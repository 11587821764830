<template>
  <div :before-change="validationForm">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- images -->
          <b-col cols="12">
            <b-card-code title="Images">
              <b-row>
                <b-col md="4">
                  <div align="center" class="text-center">
                    <h4>Profile Picture</h4>
                    <b-media class="mb-1 mr-auto">
                      <b-avatar
                        class="rounded"
                        ref="previewEl"
                        :src="img"
                        size="180px"
                      />
                    </b-media>
                    <div class="mb-4">
                      <b-button
                        variant="primary"
                        @click="$refs.refInputEl.click()"
                      >
                        <input
                          ref="refInputEl"
                          type="file"
                          class="d-none"
                          @change="uploadImage"
                        />
                        <feather-icon icon="EditIcon" class=" " />
                      </b-button>
                    </div>
                  </div>
                </b-col>
                <!-- image ID Front -->
                <b-col md="4">
                  <div align="center" class="text-center">
                    <h4>Id Front Picture</h4>
                    <b-media class="mb-1">
                      <b-avatar
                        class="rounded"
                        ref="previewEl"
                        :src="img2"
                        size="180px"
                      />
                    </b-media>
                    <div class="mb-4">
                      <b-button
                        variant="primary"
                        @click="$refs.refInputEl2.click()"
                      >
                        <input
                          ref="refInputEl2"
                          type="file"
                          class="d-none"
                          @change="uploadImage2"
                        />
                        <feather-icon icon="EditIcon" class="" />
                      </b-button>
                    </div>
                  </div>
                </b-col>
                <!-- image ID Back -->
                <b-col md="4">
                  <div align="center" class="text-center">
                    <h4>Id Back Picture</h4>
                    <b-media class="mb-2">
                      <b-avatar
                        class="rounded"
                        ref="previewEl"
                        :src="img3"
                        size="180px"
                      />
                    </b-media>
                    <div class="mb-4">
                      <b-button
                        variant="primary"
                        @click="$refs.refInputEl3.click()"
                      >
                        <input
                          ref="refInputEl3"
                          type="file"
                          class="d-none"
                          @change="uploadImage3"
                        />
                        <feather-icon icon="EditIcon" class="" />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-code>
          </b-col>
          <!-- personal Details -->
          <b-col cols="12">
            <b-card-code title="Personal Details">
              <b-row>
                <b-col md="6">
                  <!-- firstname -->
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter First Name'"
                    label="First Name"
                    label-for="firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="firstname"
                      rules="required"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="vendorData.firstname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="jhon"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lastname -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Last Name'"
                    label="Last Name"
                    label-for="lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="lastname"
                      rules="required"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="vendorData.lastname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="wick"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- idNumber -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter ID Number'"
                    label="ID Number"
                    label-for="idnumber"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ID Number"
                      rules="required"
                    >
                      <b-form-input
                        id="idnumber"
                        v-model="vendorData.idnumber"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter 14 number of vendor ID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Email -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Valid Email'"
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="vendorData.email"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Example@MobileMasr.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- BirthDate -->
                <b-col md="6">
                  <b-form-group label="Birthdate" label-for="Birthdate">
                    <flat-pickr
                      v-model="vendorData.birthdate"
                      class="form-control"
                      placeholder="12/12/2020"
                      :config="{
                        maxDate: maxDate,
                      }"
                    />
                  </b-form-group>
                </b-col>
                
                <b-col md="6">

                  <b-row>
                    <!-- Portal Option -->
                    <b-col md="6">
                      <b-form-group label="Is Portal ?" label-for="portal">
                        <validation-provider
                          #default="{ errors }"
                          name="portal"
                          rules="required"
                        >
                          <b-form-checkbox switch v-model="vendorData.portal">
                            <p v-if="vendorData.portal">
                              Can Upload Portal Products
                            </p>
                            <p v-else>Can't Upload Portal Products</p>
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- In Store Option -->
                    <b-col md="6">
                      <b-form-group label="Is In Store ?" label-for="in_store">
                        <validation-provider
                          #default="{ errors }"
                          name="in_store"
                          rules="required"
                        >
                          <b-form-checkbox switch v-model="vendorData.in_store">
                            <p v-if="vendorData.in_store">
                              Can Upload In Store Products
                            </p>
                            <p v-else>Can't Upload In Store Products</p>
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>

                </b-col>
                
                <b-col md="6">

                  <b-row>
                    <!-- Change Price Option -->
                    <b-col md="12">
                      <b-form-group label="Can Update Price ?" label-for="can_update_price">
                        <validation-provider
                          #default="{ errors }"
                          name="can_update_price"
                          rules="required"
                        >
                          <b-form-checkbox switch v-model="vendorData.can_update_price">
                            <p v-if="vendorData.can_update_price">
                              Can Update Products Price 
                            </p>
                            <p v-else>Can't Update Products Price</p>
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>


                </b-col>

                <b-col md="6">

                  <b-row>
                    <!-- Sell New Option -->
                    <b-col md="6">
                      <b-form-group label="Can Sell New ?" label-for="is_sell_new">
                        <validation-provider
                          #default="{ errors }"
                          name="is_sell_new"
                          rules="required"
                        >
                          <b-form-checkbox switch v-model="vendorData.is_sell_new">
                            <p v-if="vendorData.is_sell_new">
                              Can Sell New Products
                            </p>
                            <p v-else>Can't Sell New Products</p>
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Sell Used Option -->
                    <b-col md="6">
                      <b-form-group label="Is Sell Used ?" label-for="is_sell_used">
                        <validation-provider
                          #default="{ errors }"
                          name="is_sell_used"
                          rules="required"
                        >
                          <b-form-checkbox switch v-model="vendorData.is_sell_used">
                            <p v-if="vendorData.is_sell_used">
                              Can Sell Used Products
                            </p>
                            <p v-else>Can't Sell Used Products</p>
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>

                </b-col>
              
              </b-row>
            </b-card-code>
          </b-col>
          <!-- vendor Details -->
          <b-col cols="12">
            <b-card-code title="Vendor Details">
              <b-row>
                <!-- storeName -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Store Name'"
                    label="Store Name"
                    label-for="storename"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Store Name"
                      rules="required"
                    >
                      <b-form-input
                        id="storename"
                        v-model="vendorData.storename"
                        :state="errors.length > 0 ? false : null"
                        placeholder="MobileMasr"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- address -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Address'"
                    label="Address"
                    label-for="address"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <b-form-input
                        id="address"
                        v-model="vendorData.address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="maddi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- phone -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Phone'"
                    label="Phone"
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="egyptPhone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="vendorData.phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="02XXXXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- country -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-group
                      label="Country"
                      label-for="country"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="country"
                        v-model="countrySelect"
                        :state="countrySelect == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- city -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <b-form-group
                      label="City"
                      label-for="city"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="city"
                        v-model="citySelect"
                        :state="citySelect == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="cityOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- area -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Area"
                    rules="required"
                  >
                    <b-form-group
                      label="Area"
                      label-for="area"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="area"
                        v-model="areaSelect"
                        :state="areaSelect == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="areaOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- location -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Location'"
                    label="Location"
                    label-for="location"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Location"
                      rules="required"
                    >
                      <b-form-input
                        id="location"
                        v-model="vendorData.orderlocation"
                        :state="errors.length > 0 ? false : null"
                        placeholder="maddi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- self management -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Self management"
                    rules="required"
                  >
                    <b-form-group
                      label="self management"
                      label-for="self management"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="self-management"
                        v-model="self_managementSelected"
                        :state="self_managementSelected == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="self_managementOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- account manager -->
                <b-col
                  md="6"
                  v-if="
                    self_managementSelected != null &&
                    self_managementSelected.value == '0'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Account manager"
                    rules="required"
                  >
                    <b-form-group
                      label="account manager"
                      label-for="account manager"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="account-manager"
                        v-model="account_managerSelected"
                        :state="account_managerSelected == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="account_managerOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-code>
          </b-col>
          <!-- internal manager -->
          <b-col cols="12">
            <b-card title="Internal Manager">
              <b-row>
                <b-col md="6">
                  <!-- name -->
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="vendorData.internal_manager_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="jhon"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Mobile Number" label-for="mobilenumber">
                    <validation-provider
                      #default="{ errors }"
                      name="Mobile Number"
                      rules="required|egyptMobile"
                    >
                      <b-form-input
                        v-model="vendorData.internal_manager_phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="01XXXXXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-card>
              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import moment from 'moment'
import BCardCode from '@core/components/b-card-code'
import {
  required,
  email,
  integer,
  egyptID,
  egyptMobile,
  egyptPhone,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCardCode,
    flatPickr,
  },
  mixins: [heightTransition],
  data() {
    return {
      maxDate: null,
      id: 0,
      countryID: '',
      cityID: '',
      city: {},
      success_message: '',
      showDismissibleAlert: false,
      errors_back: [],
      required,

      egyptID,
      egyptMobile,
      egyptPhone,
      email,
      integer,
      img: '',
      img2: '',
      img3: '',
      filed: '',
      upplodedFile: null,
      areaOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      areaSelect: null,
      self_managementOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '1',
          text: 'True',
        },
        {
          value: '0',
          text: 'False',
        },
      ],
      self_managementSelected: {
        text: '',
        value: '',
      },
      account_managerSelected: {
        text: '',
        value: '',
      },
      account_managerOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      vendorData: {
        firstname: '',
        lastname: '',
        idnumber: '',
        email: '',
        storename: '',
        birthdate: '',
        address: '',
        phone: '',
        portal: false,
        in_store: false,
        can_update_price: false,
        is_sell_new: false,
        is_sell_used: false,

        area: {
          city_id: '',
        },
        orderlocation: '',
        password: '',
        password_confirmation: '',
        phoneNumber: [
          {
            id: '',
            mobile: '',
          },
        ],

        type: '',
      },
      sendData: {
        firstname: '',
        lastname: '',
        idnumber: '',
        email: '',
        storename: '',
        birthdate: '',
        address: '',
        area_id: '',
        orderlocation: '',
        self_management: null,
        internal_manager_name: null,
        internal_manager_phone: null,
      },
      countryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      countrySelect: {
        text: '',
        value: '',
      },
      cityOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      citySelect: {
        text: '',
        value: '',
      },
      areaOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      areaSelect: {
        text: '',
        value: '',
      },
    }
  },
  watch: {
    'vendorData.phoneNumbers': function () {
      this.initTrHeight()
    },
    countrySelect: function () {
      if (this.countrySelect != null && this.countrySelect.text != '') {
        this.countrySelected()
      } else {
        this.countrySelect = null
        this.citySelect = null
        this.areaSelect = null
      }
    },
    citySelect: function () {
      if (this.citySelect != null && this.citySelect.text != '') {
        this.citySelected()
      } else {
        this.citySelect = null
        this.areaSelect = null
      }
    },
    self_managementSelected: function () {
      if (this.self_managementSelected.value == '0') {
        this.getAdmins()
      }
    },
  },
  created() {
    this.maxDate = moment().subtract(18, 'years').format('YYYY-MM-DD')
    this.id = this.$route.params.id
    axios
      .get('vendors/' + this.id + '/show')
      .then((result) => {
        this.vendorData = result.data.data
        this.img = this.vendorData.profile_picture
        this.img2 = this.vendorData.idfront_picture
        this.img3 = this.vendorData.idback_picture

        const CityI = this.vendorData.area
        axios
          .get('cities/' + CityI.city.id.toString() + '/areas')
          .then((result) => {
            const data = result.data.data
            for (let are in data) {
              this.areaOptions.push({
                text: data[are].name,
                value: data[are].id.toString(),
              })
              if (this.vendorData.area.id == data[are].id) {
                this.areaSelect.value = data[are].id.toString()
                this.areaSelect.text = data[are].name
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
        this.countryID = this.vendorData.area.city
        axios
          .get('countries')
          .then((result) => {
            const data = result.data.data
            for (let ind in data) {
              this.countryOptions.push({
                text: data[ind].name,
                value: data[ind].id.toString(),
              })
              if (data[ind].id == this.countryID.country.id) {
                this.countrySelect.value = data[ind].id.toString()
                this.countrySelect.text = data[ind].name
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
        axios
          .get('country/' + this.countryID.country.id.toString() + '/cities')
          .then((result) => {
            const data = result.data.data
            for (let ind in data) {
              this.cityOptions.push({
                text: data[ind].name,
                value: data[ind].id.toString(),
              })
              if (this.vendorData.area.city.id == data[ind].id) {
                this.citySelect.value = data[ind].id.toString()
                this.citySelect.text = data[ind].name
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
        if (this.vendorData.self_management == true) {
          this.self_managementSelected.value = '1'
          this.self_managementSelected.text = 'True'
        } else {
          this.self_managementSelected.value = '0'
          this.self_managementSelected.text = 'false'
          this.getAdmins()
          this.account_managerSelected.text =
            this.vendorData.account_manager.firstname +
            ' ' +
            this.vendorData.account_manager.lastname
          this.account_managerSelected.value =
            this.vendorData.account_manager.id.toString()
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    // sumbit new role
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.img.includes('data:image/')) {
            this.sendData.profile_picture = this.img
              .split(',')
              .map((item) => item.trim())
            this.sendData.profile_picture = this.sendData.profile_picture[1]
          }
          if (this.img2.includes('data:image/')) {
            this.sendData.idfront_picture = this.img2
              .split(',')
              .map((item) => item.trim())
            this.sendData.idfront_picture = this.sendData.idfront_picture[1]
          }
          if (this.img3.includes('data:image/')) {
            this.sendData.idback_picture = this.img3
              .split(',')
              .map((item) => item.trim())
            this.sendData.idback_picture = this.sendData.idback_picture[1]
          }
          this.sendData.firstname = this.vendorData.firstname
          this.sendData.lastname = this.vendorData.lastname
          this.sendData.idnumber = this.vendorData.idnumber
          this.sendData.email = this.vendorData.email
          this.sendData.storename = this.vendorData.storename
          this.sendData.birthdate = this.vendorData.birthdate
          this.sendData.address = this.vendorData.address
          this.sendData.orderlocation = this.vendorData.orderlocation
          if (
            this.vendorData.phone != '' &&
            this.vendorData.phone != 'null' &&
            this.vendorData.phone != null
            ) {
            this.sendData.phone = parseInt(this.vendorData.phone)
          }else{
            this.sendData.phone = null

          }
          this.sendData.area_id = this.areaSelect.value

          this.sendData.self_management = this.self_managementSelected.value
          if (this.sendData.self_management == false) {
            this.sendData.account_manager_id =
              this.account_managerSelected.value
          }
          this.sendData.internal_manager_name =
            this.vendorData.internal_manager_name
          this.sendData.internal_manager_phone =
            this.vendorData.internal_manager_phone
            
          this.sendData.portal = this.vendorData.portal
          this.sendData.in_store = this.vendorData.in_store
          this.sendData.can_update_price = this.vendorData.can_update_price
          this.sendData.is_sell_new = this.vendorData.is_sell_new
          this.sendData.is_sell_used = this.vendorData.is_sell_used
          axios
            .post(
              'agency/vendors/' + this.id + '/update-profile',
              this.sendData
            )
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0

              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // get admins
    getAdmins() {
      axios
        .get('admins/account-manager')
        .then((result) => {
          //console.log(result);
          this.account_managerOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          const data = result.data.data
          for (let index in data) {
            this.account_managerOptions.push({
              text: data[index].firstname + ' ' + data[index].lastname,
              value: data[index].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    wait(ms) {
      var start = new Date().getTime()
      var end = start
      while (end < start + ms) {
        end = new Date().getTime()
      }
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage2(files[0])
    },
    createImage2(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img2 = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id back picture
    uploadImage3(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage3(files[0])
    },
    createImage3(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img3 = e.target.result
      }
      reader.readAsDataURL(file)
    },
    countrySelected() {
      this.city = false
      axios
        .get('country/' + this.countrySelect.value + '/cities')
        .then((result) => {
          const data = result.data.data
          this.citySelect = null
          this.areaSelect = null
          this.cityOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          for (let ind in data) {
            this.cityOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    citySelected() {
      this.area = false
      axios
        .get('cities/' + this.citySelect.value + '/areas')
        .then((result) => {
          const data = result.data.data
          this.areaSelect = null
          this.areaOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          for (let ind in data) {
            this.areaOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
